<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a class="nav__btn nav__btn--prev">
			</a>
			<p class="nav__item">
				<span class="nav__text">
					픽
				</span>
			</p>
			<a class="nav__btn nav__btn--map">
			</a>
		</div>
	</nav>
	<!-- //nav-->
	<div id="container" class="container">
		<div id="sub" class="sub pick">
			<!-- [D] 활성화된 tab에 active 클래스 추가 -->
			<section class="listcartegory">
				<div class="box">
					<div class="tab tab--half">
						<a href="#">숙박</a>
						<a href="#" class="active">레저·티켓</a>
					</div>
				</div>
			</section>
			<!-- category -->
			<section class="listsearch">
				<div class="listsearch__wrap">
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-date"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="09.11(목)~09.12(금)" readonly>
						</div>
					</div>
					<div class="listsearch__inner">
						<div class="listsearch__box">
							<span class="listsearch__icon">
								<i class="icon icon-group"></i>
							</span>
							<input type="text" class="listsearch__ipt" value="성인 2명, 아동 2명" readonly>
						</div>
					</div>
				</div>
			</section>
			<!-- content -->
			<section class="content">
				<div class="listbox listbox--first">
					<!-- [REST] 숙소 리스트 -->
					<ul class="list list--full">
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼벤자민모텔">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													패러글라이딩 체험비행 명가
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title" style="display:inline-block">
												[충북] 단양 패러글라이딩
											</h3>
										</div>
                                        <ul class="list__info">
                                            <li>
                                                <div class="list__info-desc">
                                                    당일사용가능 <br>
                                                    미사용 전액 환불
                                                </div>
                                                <div class="list__info-price info-price">
                                                    <div class="info-price__sale">
                                                        <strong>14%</strong>
                                                        <span>35,000</span>
                                                    </div>
                                                    <div class="info-price__current">
                                                        <strong>25,000</strong>원
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
									</div>
								</router-link>
								<ul class="item__menu item__menu--top">
									<li>
										<a href="#">
											<i class="icon icon-pick--point2">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
						<li>
							<div class="item">
								<router-link to="/rooms/1" class="list__link">
									<!-- [D] 뱃지 있을 시 추가 -->
									<div class="card__badge badge badge--coupon">
										<span class="badge__text">
											1만원 할인쿠폰
										</span>
									</div>
									<div class="list__wrap">
										<div class="list__image">
											<img src="@/assets/images/temp-leisure01.jpg" alt="역삼벤자민모텔">
										</div>
										<div class="list__data">
											<div class="list__box flex">
												<div class="list__slogan info-slogan">
													패러글라이딩 체험비행 명가
												</div>
												<div class="list__grad info-grad">
												<span class="list__icon info-icon">
													<i class="icon icon-star"></i>
												</span>
													<span class="list__gradnum info-gradnum">
													9.7
												</span>
													<span class="list__gradcounter info-gradcounter">
													(2,400)
												</span>
												</div>
											</div>
											<h3 class="list__title info-title" style="display:inline-block">
												[충북] 단양 패러글라이딩
											</h3>
										</div>
										<ul class="list__info">
											<li>
												<div class="list__info-desc">
													당일사용가능 <br>
													미사용 전액 환불
												</div>
												<div class="list__info-price info-price">
													<div class="info-price__sale">
														<strong>14%</strong>
														<span>35,000</span>
													</div>
													<div class="info-price__current">
														<strong>25,000</strong>원
													</div>
												</div>
											</li>
										</ul>
									</div>
								</router-link>
								<ul class="item__menu item__menu--top">
									<li>
										<a href="#">
											<i class="icon icon-pick--point2">쿠폰</i>
										</a>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<TheNavigation></TheNavigation>
</template>